import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "availableCards",
    "availableCardsTrigger"
  ];

  connect() {
    this.availableCardsTriggerTargets.forEach((labelEl) => {
      const identifier      = labelEl.dataset.availableCardsTriggerValue;
      let checkboxContainer = labelEl.querySelector(`[data-checkbox-container="${identifier}"]`);

      if (checkboxContainer) {
        let checkbox = checkboxContainer.querySelector('input');

        if (checkbox.checked) {
          this.selectOption(labelEl);
        }
      }
    })
  }

  selectOption(eventOrEl) {
    let labelEl = eventOrEl;

    if (eventOrEl instanceof Event) {
      labelEl = eventOrEl.target;
    }

    if(labelEl.tagName === 'LABEL' && labelEl.dataset.availableCardsTriggerValue) {
      this.availableCardsTargets.forEach((ac) => ac.classList.add('d-none'));
      const cardIdentifier = labelEl.dataset.availableCardsTriggerValue;

      const cardEl = this.availableCardsTargets.find((ac) => {
        return ac.dataset.availableCards === cardIdentifier;
      });

      if (cardEl) {
        cardEl.classList.remove('d-none');
      }
    }
  }
}
