// // Entry point for the build script in your package.json
// // This file is automatically compiled by Webpack, along with any other files
// // present in this directory. You're encouraged to place your actual application logic in
// // a relevant structure within app/javascript and only use these pack files to reference
// // that code so it'll be compiled.

// //= require activestorage

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import { Tooltip } from "bootstrap";

import "@hotwired/turbo-rails";
import "chartkick/chart.js";
import "./channels/**/*.js"
import "./controllers";
import "./viewport";

import "@lottiefiles/lottie-player";
import { Turbo } from "@hotwired/turbo-rails";

require("trix");
require("@rails/actiontext");

require('./vendor/trustpilot-5');

Rails.start();
ActiveStorage.start();
Turbo.start();


document.addEventListener('turbo:before-visit', () => {
  const pageLoader     = document.querySelector('.page-loader');
  const yieldedContent = document.querySelector('.yielded-content');

  if (pageLoader && yieldedContent) {
    pageLoader.classList.remove('d-none');
    yieldedContent.classList.add('d-none');
  }

});

document.addEventListener('turbo:visit', () => {
  const pageLoader     = document.querySelector('.page-loader');
  const yieldedContent = document.querySelector('.yielded-content');

  if (pageLoader && yieldedContent) {
    pageLoader.classList.add('d-none');
    yieldedContent.classList.remove('d-none');
  }
});

document.addEventListener('turbo:load', () => {
  var tooltipTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="tooltip"]')
  );

  tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl);
  });

  const pageLoader     = document.querySelector('.page-loader');
  const yieldedContent = document.querySelector('.yielded-content');

  if (pageLoader && yieldedContent) {
    pageLoader.classList.add('d-none');
    yieldedContent.classList.remove('d-none');
  }
});
