import { Controller } from "@hotwired/stimulus";
import { loading } from '../utils/_loading';
import isEmpty from '../utils/_empty';

export default class extends Controller {
  static targets = [
    'confirmationModal',
    'content',
    'paymentOptions'
  ];

  static values  = {
    calendarUrl:  String,
    merchant:     String,
    slug:         String
  }

  connect() {
    this.confirmationModalTarget.addEventListener('show.bs.modal', _event => {
      this.blockSlots();
    });
  }

  bookSlots(e) {
    let clickable;
    let form               = e.target.closest('form');
    let formData           = new FormData(form);
    const csrfTokenMetaTag = document.querySelector('meta[name="csrf-token"]')
    const csrfToken        = csrfTokenMetaTag ? csrfTokenMetaTag.getAttribute('content') : null;
    let filtered           = [];
    let errors             = [];

    this.collect_inputs(filtered, form);

    filtered.forEach((field) => {
      if (isEmpty(field.value)) {
        const accordionItem     = field.closest('.accordion-item');
        clickable               = accordionItem.querySelector('.accordion-button');
        field.style.borderColor = 'red';

        errors.push([field, clickable]);
      } else {
        field.style.borderColor = 'rgba(0,0,0,0.08)';
      }
    });

    if (errors.length > 0) {
      const htmlBtn = errors[0][1];
      if (htmlBtn.classList.contains('collapsed')) {
        htmlBtn.click();
      }
    }

    if (errors.length === 0) {
      this.contentTarget.innerHTML = '';
      this.contentTarget.classList.add('p-5');
      this.contentTarget.innerHTML = loading('Preparing booking');

      fetch(form.action, {
        method: "POST",
        body: formData,
        headers: {
          'X-CSRF-Token': csrfToken
        },
        credentials: 'include'
      })
      .then(response => response.json())
      .then(result => {
        window.location = result.data.redirect_url;
      })
    }
  }

  blockSlots() {
    this.contentTarget.classList.add('p-5');
    this.contentTarget.innerHTML = loading();

    let form               = new FormData();
    const csrfTokenMetaTag = document.querySelector('meta[name="csrf-token"]')
    const csrfToken        = csrfTokenMetaTag ? csrfTokenMetaTag.getAttribute('content') : null;

    form.append('availability', JSON.stringify({
      product_id:  this.selectedProduct().dataset.id,
      merchant_id: this.selectedMerchantId(),
      slots: {
        day:  this.selectedDate().value,
        slot: this.selectedTimeSlot().dataset.id
      }
    }));

    fetch('/bookings/availability', {
      method: "PUT",
      body: form,
      headers: {
        'X-CSRF-Token': csrfToken
      },
      credentials: 'include'
    })
    .then(response => response.json())
    .then(result => {
      const existingInputs = document.querySelectorAll('input[name="booking[booking_id]"]');
      existingInputs.forEach(i => i.remove());

      let hiddenInput    = document.createElement('input');
      hiddenInput.type   = 'hidden';
      hiddenInput.name   = 'booking[booking_id]';
      hiddenInput.value  = result.data.booking_id;

      this.contentTarget.closest('form').appendChild(hiddenInput);

      this.contentTarget.classList.remove('p-5')
      this.contentTarget.innerHTML = result.data.html;
      this.paymentOptionsTarget.classList.remove('d-none')
    });
  }

  cancelConfirmation() {
    this.contentTarget.innerHTML = loading('Releasing dates');
    let form               = new FormData();
    const csrfTokenMetaTag = document.querySelector('meta[name="csrf-token"]')
    const csrfToken        = csrfTokenMetaTag ? csrfTokenMetaTag.getAttribute('content') : null;

    form.append('availability', JSON.stringify({
      product_id: this.selectedProduct().dataset.id,
      merchant_id: this.selectedMerchantId(),
      slots: {
        day:  this.selectedDate().value,
        slot: this.selectedTimeSlot().dataset.id
      }
    }));

    this.resetAvailability();

    fetch('/bookings/availability', {
      method: "DELETE",
      body: form,
      headers: {
        'X-CSRF-Token': csrfToken
      },
      credentials: 'include'
    })
    .then(response => response.json())
    .then(result => {
      this.contentTarget.innerHTML = result.data.html;
    })
  }

  resetAvailability() {
    const slotsSelector = document.querySelector('[data-target="calendar.formDetails"]');
    const emptyState    = document.querySelector('[data-target="calendar.emptyState"]');
    const dateInput     = this.selectedDate();

    slotsSelector.classList.add('d-none');
    emptyState.classList.remove('d-none');

    dateInput.checked = false;
    dateInput.parentElement.style.backgroundColor = '#f8f9fa';

    this.clearBookingIds();
  }

  selectedProduct() {
    const products = document.querySelectorAll('[data-target*="calendar.product"]');

    return Array.from(products).find(x => {
      return x.querySelector('input:checked');
    });
  }

  selectedTimeSlot() {
    const timeSlots = document.querySelectorAll('[data-target="calendar.timeSlot"]');

    return Array.from(timeSlots).find(x => {
      return x.querySelector('input:checked');
    });
  }

  selectedDate() {
    const dates = document.querySelectorAll('[data-date-item]');

    return Array.from(dates).find(x => {
      return x.checked;
    });
  }

  selectedMerchantId() {
    return document.querySelector('select#merchant_id').querySelector('option[selected="selected"]').value;
  }

  clearBookingIds() {
    const existingInputs = document.querySelectorAll('input[name="booking[booking_id]"]');
    existingInputs.forEach(i => i.remove());
  }

  collect_inputs(collection, form) {
    form.querySelectorAll('input').forEach((field) => {
      if(!['hidden', 'submit'].includes(field.type) && field.required) {
        collection.push(field);
      }
    });
  }
}
